import styled from 'styled-components';

export const CardContainer = styled.div`
  border-top: 1px solid #ededed;
  display: flex;
  padding: 0.5rem;
  padding-bottom: 0;
  align-items: center;
  justify-content: space-between;

  .hide-action {
    cursor: pointer;
    color: #56069e;
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const MessageContainer = styled.div`
  display: flex;
`;

export const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ededed;
  padding-right: 1rem;
  min-width: 85px;
  width: 85px;

  span {
    font-weight: bold;
    border-bottom: 1px solid black;
  }
`;

export const TypeIcon = styled.div`
  font-size: 1.5rem;
  color: #8850bf;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;
